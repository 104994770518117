import enLocale from 'element-ui/lib/locale/lang/en';
import koLocale from 'element-ui/lib/locale/lang/ko';
import zhTwLocale from 'element-ui/lib/locale/lang/zh-TW';
import Cookies from 'js-cookie';
import Vue from "vue";
import VueI18n from "vue-i18n";
import store from "../store";
import en from './en.json';
import ko from './ko.json';
import zhTW from './zhtw.json';

Vue.use(VueI18n);


export const i18n = new VueI18n({
  locale: 'zh-TW',
  messages: {
    'zh-TW': Object.assign(zhTwLocale, zhTW),
    'en':Object.assign(enLocale, en),
    'ko':Object.assign(koLocale, ko),
  },
});


export const loadLang = function (){
  const lang = Cookies.get('lang');
  store.lang = 'zh-TW';
  if(lang){
    store.lang = lang;
  }
  i18n.locale = store.lang
}

export const saveLang = function (){
  Cookies.set('lang', store.lang);
  i18n.locale = store.lang
 
}



// export default i18n;


// export const {
//   i18n,
//   loadLang,
//   saveLang
// };


