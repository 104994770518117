<template>
  <v-dialog v-model="registerFlag" :fullscreen="isMobile" max-width="460">
    <v-card class="login-container" style="border-radius: 4px; height: 550px">
      <v-icon class="float-right" @click="registerFlag = false">
        mdi-close
      </v-icon>
      <div class="login-wrapper">
        <template v-if="useMobile">
          <!-- 用户名 -->
          <div style="font-size: 12px">手机号</div>
          <el-input placeholder="请输入您的手机号" v-model="mobile"></el-input>

          <!-- 密码 -->
          <div style="font-size: 12px; margin-top: 10px">密码</div>
          <el-input
            placeholder="请输入您的密码"
            v-model="password"
            show-password
            type="password"
          ></el-input>
          <div v-if="false">
            <!-- 验证码 -->
            <div style="font-size: 12px; margin-top: 10px">验证码</div>
            <div class="send-wrapper">
              <ElInput
                maxlength="6"
                v-model="smsCode"
                placeholder="请输入6位验证码"
                @keyup.enter="register"
              />
              <v-btn
                text
                small
                :disabled="!enabledSendSMSCode || time < 60"
                @click="sendSMSCode"
              >
                {{ codeMsg }}
              </v-btn>
            </div>
          </div>
        </template>

        <template v-else>
          <!-- 用户名 -->
          <div style="font-size: 12px">邮箱名</div>
          <el-input
            placeholder="请输入您的邮箱号"
            v-model="username"
            type="email"
          ></el-input>
          <!-- 密码 -->
          <div style="font-size: 12px; margin-top: 10px">密码</div>
          <el-input
            placeholder="请输入您的密码"
            v-model="password"
            show-password
            type="password"
          ></el-input>

          <!-- 验证码 -->
          <div v-if="false">
            <div style="font-size: 12px; margin-top: 10px">验证码</div>
            <div class="send-wrapper">
              <ElInput
                maxlength="6"
                v-model="code"
                placeholder="请输入6位验证码"
                @keyup.enter="register"
              />
              <v-btn text small :disabled="flag" @click="sendCode">
                {{ codeMsg }}
              </v-btn>
            </div>
          </div>
        </template>

        <!-- 注册按钮 -->
        <v-btn
          class="mt-7"
          block
          color="red"
          style="color: #fff"
          @click="register"
        >
          注册
        </v-btn>

        <div
          class="mt-5 text-sm cursor-pointer"
          @click="useMobile = !useMobile"
        >
          {{ useMobile ? "邮箱注册" : "手机注册" }}
        </div>

        <!-- 登录 -->
        <div class="mt-5 login-tip">
          已有账号？<span @click="openLogin">登录</span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  emailLogin,
  emailRegister,
  mobileLogin,
  mobileRegister,
  sendEmailCode,
  sendSMSCode,
} from "@/api";

export default {
  data: function () {
    return {
      username: "",
      code: "",
      password: "",
      nickname: "",
      flag: true,
      codeMsg: "发送",
      time: 60,
      show: false,
      useMobile: true,
      mobile: "",
      smsCode: "",
    };
  },
  methods: {
    openLogin() {
      this.$store.state.registerFlag = false;
      this.$store.state.loginFlag = true;
    },
    sendCode() {
      //发送邮件
      this.countDown();
      sendEmailCode(this.username)
        .then((res) => {
          this.$toast({ type: "success", message: res.message });
        })
        .catch((err) => {
          this.$toast({ type: "error", message: err.message });
        });
    },
    countDown() {
      this.flag = true;
      this.timer = setInterval(() => {
        this.time--;
        this.codeMsg = this.time + "s";
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.codeMsg = "发送";
          this.time = 60;
          this.flag = false;
        }
      }, 1000);
    },
    sendSMSCode() {
      if (!this.isMobileNumber(this.mobile)) {
        this.$toast({ type: "error", message: "手机格式不正确" });
        return;
      }
      this.countDown();
      sendSMSCode(this.mobile, 0)
        .then((res) => {
          this.$toast({ type: "success", message: res.message });
        })
        .catch((err) => {
          this.$toast({ type: "error", message: err.message });
        });
    },
    register() {
      if (this.useMobile) {
        if (!this.isMobileNumber(this.mobile)) {
          this.$toast({ type: "error", message: "手机格式不正确" });
          return;
        }
        this.smsCode = "123456";
        if (this.smsCode.trim().length !== 6) {
          this.$toast({ type: "error", message: "请输入6位验证码" });
          return;
        }
        if (this.password.trim().length < 6) {
          this.$toast({ type: "error", message: "密码不能少于6位" });
          return;
        }
        this.nickname = this.mobile.slice(0, 3);
        const user = {
          tel: this.mobile,
          password: this.password,
          nickname: this.nickname,
          code: this.smsCode,
        };
        mobileRegister(user)
          .then((res) => {
            mobileLogin({ tel: user.tel, password: user.password }).then(
              (res) => {
                // this.username = "";
                // this.password = "";
                // this.nickname = "";
                this.$store.commit("login", res.data);
                this.$store.commit("closeModel");
                this.$toast({ type: "success", message: "登录成功" });
              }
            );
          })
          .catch((err) => {
            this.$toast({ type: "error", message: err.message });
          });
      } else {
        var reg =
          /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(this.username)) {
          this.$toast({ type: "error", message: "邮箱格式不正确" });
          return false;
        }
        if (this.code.trim().length !== 6) {
          this.$toast({ type: "error", message: "请输入6位验证码" });
          return false;
        }
        if (this.password.trim().length < 6) {
          this.$toast({ type: "error", message: "密码不能少于6位" });
          return false;
        }
        // if (this.nickname.trim().length < 2) {
        //   this.$toast({ type: "error", message: "昵称不能少于2位" });
        //   return false;
        // }
        this.nickname = this.username.slice(0, 3);
        const user = {
          email: this.username,
          password: this.password,
          nickname: this.nickname,
          code: this.code,
        };
        emailRegister(user)
          .then((res) => {
            emailLogin({ email: user.email, password: user.password }).then(
              (res) => {
                // this.username = "";
                // this.password = "";
                // this.nickname = "";
                this.$store.commit("login", res.data);
                this.$store.commit("closeModel");
                this.$toast({ type: "success", message: "登录成功" });
              }
            );
          })
          .catch((err) => {
            this.$toast({ type: "error", message: err.message });
          });
      }
    },
    isMobileNumber(mobile) {
      return /^1[3-9]\d{9}$/.test(mobile);
    },
  },
  computed: {
    registerFlag: {
      set(value) {
        this.$store.state.registerFlag = value;
      },
      get() {
        return this.$store.state.registerFlag;
      },
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth > 960) {
        return false;
      }
      return true;
    },
    enabledSendSMSCode() {
      return this.isMobileNumber(this.mobile);
    },
  },
  watch: {
    username(value) {
      var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (reg.test(value)) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
  },
};
</script>
