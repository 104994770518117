<template>
  <v-app id="app">
    <!-- 导航栏 -->
    <TopNavBar></TopNavBar>
    <!-- 侧边导航栏 -->
    <SideNavBar></SideNavBar>
    <!-- 内容 -->
    <v-main>
      <router-view :key="$route.fullPath" />
    </v-main>
    <!-- 页脚 -->
    <Footer></Footer>
    <!-- 返回顶部 -->
    <BackTop></BackTop>
    <!-- 搜索模态框 -->
    <searchModel></searchModel>
    <!-- 登录模态框 -->
    <LoginModel></LoginModel>
    <!-- 注册模态框 -->
    <RegisterModel></RegisterModel>
    <!-- 忘记密码模态框 -->
    <ForgetModel></ForgetModel>
    <!-- 绑定邮箱模态框 -->
    <EmailModel></EmailModel>
    <FeedBack />
    <ChatButton v-if="showChatButton" />
  </v-app>
</template>

<script>
import TopNavBar from "./components/game/layout/TopNavBar";
import SideNavBar from "./components/game/layout/SideNavBar";
import Footer from "./components/game/layout/Footer";
import BackTop from "./components/game/BackTop";
import searchModel from "./components/model/game/SearchModel";
import LoginModel from "./components/model/game/LoginModel";
import RegisterModel from "./components/model/game/RegisterModel";
import ForgetModel from "./components/model/game/ForgetModel";
import EmailModel from "./components/model/game/EmailModel";
import { getCategoryList } from "@/api/game";
import { getWebSiteInfo, report, isLogin } from "@/api";
import FeedBack from "./components/FeedBack";
import ChatButton from "./components/ChatButton";
export default {
  mounted() {
    this.$store.state.isMobile = this.isMobile1();
    this.getBlogInfo();
    this.getCategoryList();
    // 上传访客信息
    report();
    isLogin().then((res) => {
      if (!res.data) {
        this.$store.commit("logout");
      } else {
      }
    });
  },
  components: {
    TopNavBar,
    SideNavBar,
    Footer,
    BackTop,
    searchModel,
    LoginModel,
    RegisterModel,
    ForgetModel,
    EmailModel,
    FeedBack,
    ChatButton,
  },
  methods: {
    getBlogInfo() {
      getWebSiteInfo().then((res) => {
        this.$store.commit("checkBlogInfo", res.data);
      });
    },
    getCategoryList() {
      if (this.$store.state.game.gameCategoryList) {
        return;
      }
      getCategoryList().then((res) => {
        // console.log(res);
        this.$store.commit("gameCategoryList", res.data);
      });
    },
    isMobile1() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
  },
  computed: {
    blogInfo() {
      return this.$store.state.blogInfo;
    },
    isMobile() {
      const flag = navigator.userAgent.match(
        /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
      );
      return flag;
    },
    showChatButton() {
      return this.$store.state.userId && this.$route.path !== "/chat";
    },
  },
};
</script>
