<template>
  <v-app-bar
    app
    :class="navClass"
    :hide-on-scroll="false"
    :color="isMobile ? 'tran' : 'black'"
    flat
    height="60"
    fixed
  >
    <!-- 手机端导航栏 -->
    <div class="d-md-none nav-mobile-container">
      <div style="font-size: 18px; font-weight: bold"></div>
      <div style="margin-left: auto">
        <a @click="openSearch" v-if="false"
          ><i class="iconfont iconsousuo"
        /></a>
        <a @click="openDrawer" style="margin-left: 10px; font-size: 20px">
          <i class="iconfont iconhanbao" />
        </a>
      </div>
    </div>
    <!-- 电脑导航栏 -->
    <div class="d-md-block d-none nav-container bg-black">
      <div class="float-left blog-title">
        <span style="color: white; font-size: 20px">游133</span>
        <el-menu
          :default-active="activeIndex"
          mode="horizontal"
          @select="handleSelect"
          background-color="#000000"
          text-color="#fff"
          active-text-color="#ffd04b"
        >
          <el-menu-item
            v-for="(menu, i) of menuOptions"
            :key="menu.value"
            :index="menu.value"
            :class="i === 0 ? 'iconfont iconzhuye' : ''"
          >
            <span style="font-size: large">{{ menu.label }}</span>
          </el-menu-item>
          <el-submenu index="3" v-if="false">
            <template slot="title">交易必看</template>
            <el-menu-item index="/v">业务介绍</el-menu-item>
            <el-menu-item index="2-2">服务协议</el-menu-item>
            <el-menu-item index="2-3">投诉建议</el-menu-item>
          </el-submenu>
          <el-submenu index="4" v-if="false">
            <template slot="title">交易保障</template>
            <el-menu-item index="/v">找回赔偿</el-menu-item>
            <el-menu-item index="2-2">售后服务</el-menu-item>
            <el-menu-item index="2-3">防骗提醒</el-menu-item>
          </el-submenu>
          <el-submenu index="5" v-if="false">
            <template slot="title">关于我们</template>
            <el-menu-item index="/v">我们优势</el-menu-item>
            <el-menu-item index="2-2">联系方式</el-menu-item>
          </el-submenu>
        </el-menu>
      </div>
      <div class="float-right nav-title">
        <ChatNotifyIcon
          v-if="this.$store.state.userId && this.$route.path !== '/chat'"
        ></ChatNotifyIcon>
        <div class="menus-item">
          <a
            class="menu-btn"
            v-if="!this.$store.state.avatar"
            style="margin-right: 10px"
            @click="register"
          >
            <i class="iconfont icondenglu" /> 注册
          </a>
          <a
            class="menu-btn"
            v-if="!this.$store.state.avatar"
            @click="openLogin"
          >
            <i class="iconfont icondenglu" /> 登录
          </a>
          <template v-else>
            <img
              class="user-avatar"
              :src="getFileUrl(this.$store.state.avatar)"
              height="30"
              width="30"
            />
            <ul class="menus-submenu">
              <li>
                <router-link to="/game/user">
                  <i class="iconfont icongerenzhongxin" /> 个人中心
                </router-link>
              </li>

              <li>
                <router-link to="/game/myGoods">
                  <i class="iconfont icongerenzhongxin" /> 商品管理
                </router-link>
              </li>
              <li>
                <a @click="logout"><i class="iconfont icontuichu" /> 退出</a>
              </li>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </v-app-bar>
</template>

<script>
import { logout, isLogin } from "@/api/index";
import { getFileUrl, compressFile } from "@/utils/imageUtil";
import ChatNotifyIcon from "@/components/ChatNotifyIcon.vue";

export default {
  components: { ChatNotifyIcon },
  created() {
    // console.log("isLogin");
    // isLogin().then((res) => {
    //   console.log("isLogin");
    //   console.log(res);
    //   if (!res.data) {
    //     this.$store.commit("logout");
    //   }
    // });
  },
  mounted() {
    // window.addEventListener("scroll", this.scroll);
  },
  data: function () {
    return {
      isMobile: this.$store.state.isMobile,
      navClass: "",
      menuOptions: [
        { label: "首页", value: "/" },
        { label: "我要买", value: "/game" },
        { label: "我要卖", value: "/game/pubGoods" },
        { label: "店铺专区", value: "/bussiness" },
        { label: "每日精品", value: "/auctionGoods" },
        { label: "精品历史", value: "/auctionHistory" },
      ],
    };
  },
  methods: {
    getFileUrl(url) {
      return getFileUrl(url);
    },
    scroll() {
      const that = this;
      that.scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      if (that.scrollTop > 60) {
        that.navClass = "nav-fixed";
      } else {
        that.navClass = "nav";
      }
    },
    handleSelect(path, keyPath) {
      if (this.$route.path === path) {
        return;
      }
      if (path === "/game/pubGoods") {
        if (!this.$store.state.userId) {
          this.$store.state.loginFlag = true;
        } else {
          this.$router.push({ path });
        }
      } else if (path === "/auctionGoods") {
        this.$router.push({
          path,
          query: { categoryId: "451" },
        });
      } else {
        this.$router.push({ path });
      }
    },
    openSearch() {
      this.$store.state.searchFlag = true;
    },
    openDrawer() {
      this.$store.state.drawer = true;
    },
    register() {
      this.$store.state.registerFlag = true;
    },
    openLogin() {
      this.$store.state.loginFlag = true;
    },
    logout() {
      //如果在个人中心则跳回上一页
      if (this.$route.path === "/user") {
        this.$router.go(-1);
      }
      logout()
        .then((res) => {
          this.$toast({ type: "success", message: "注销成功" });
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.$store.commit("logout");
          if (this.$route.path !== "/") {
            this.$router.push({ path: "/" });
          }
        });
    },
  },
  computed: {
    avatar() {
      return this.$store.state.avatar;
    },
    blogInfo() {
      return this.$store.state.blogInfo;
    },
    activeIndex() {
      const path = this.$route.path;
      return this.menuOptions.map((item) => item.value).includes(path)
        ? path
        : "/";
    },
  },
};
</script>

<style scoped>
i {
  margin-right: 4px;
}
ul {
  list-style: none;
}
.nav {
  background: rgba(0, 0, 0, 0) !important;
}
.nav a {
  color: #eee !important;
}
.nav .menu-btn {
  text-shadow: 0.05rem 0.05rem 0.1rem rgba(0, 0, 0, 0.3);
}
.nav .blog-title a {
  text-shadow: 0.1rem 0.1rem 0.2rem rgba(0, 0, 0, 0.15);
}
.theme--light.nav-fixed {
  background: rgba(255, 255, 255, 0.8) !important;
  box-shadow: 0 5px 6px -5px rgba(133, 133, 133, 0.6);
}
.theme--dark.nav-fixed {
  background: rgba(18, 18, 18, 0.8) !important;
}
.theme--dark.nav-fixed a {
  color: rgba(255, 255, 255, 0.8) !important;
}
.theme--light.nav-fixed a {
  color: #4c4948 !important;
}
.nav-fixed .menus-item a,
.nav-fixed .blog-title a {
  text-shadow: none;
}
.nav-container {
  font-size: 14px;
  width: 100%;
  height: 100%;
}
.nav-mobile-container {
  width: 100%;
  display: flex;
  align-items: center;
}
.blog-title,
.nav-title {
  display: flex;
  align-items: center;
  height: 100%;
}
.blog-title a {
  font-size: 18px;
  font-weight: bold;
}
.menus-item {
  position: relative;
  display: inline-block;
  margin: 0 0 0 0.875rem;
}
.menus-item a {
  transition: all 0.2s;
}
.nav-fixed .menu-btn:hover {
  color: #49b1f5 !important;
}
.menu-btn:hover:after {
  width: 100%;
}
.menus-item a:after {
  position: absolute;
  bottom: -5px;
  left: 0;
  z-index: -1;
  width: 0;
  height: 3px;
  background-color: #80c8f8;
  content: "";
  transition: all 0.3s ease-in-out;
}
.user-avatar {
  cursor: pointer;
  border-radius: 50%;
}
.menus-item:hover .menus-submenu {
  display: block;
}
.menus-submenu {
  position: absolute;
  display: none;
  right: 0;
  width: max-content;
  margin-top: 8px;
  box-shadow: 0 5px 20px -4px rgba(0, 0, 0, 0.5);
  background-color: #fff;
  animation: submenu 0.3s 0.1s ease both;
}
.menus-submenu:before {
  position: absolute;
  top: -8px;
  left: 0;
  width: 100%;
  height: 20px;
  content: "";
}
.menus-submenu a {
  line-height: 2;
  color: #4c4948 !important;
  text-shadow: none;
  display: block;
  padding: 6px 14px;
}
.menus-submenu a:hover {
  background: #4ab1f4;
}
@keyframes submenu {
  0% {
    opacity: 0;
    filter: alpha(opacity=0);
    transform: translateY(10px);
  }
  100% {
    opacity: 1;
    filter: none;
    transform: translateY(0);
  }
}

.nav-container .el-menu.el-menu--horizontal {
  border: none;
  text-align: center;
}

/* 改变标题栏高度 */
.nav-container .el-menu--horizontal > .el-menu-item {
  height: 50px;
  line-height: 50px;
}

.nav-container .el-menu--horizontal > .el-menu-item .el-submenu__title {
  width: 120px;
  color: white;
}
/* 标题颜色改变 */
.nav-container .el-menu--horizontal > .el-submenu .el-submenu__title {
  width: 120px;
  color: white;
}

/* 消除动态添加的三角 */
.el-icon-arrow-down:before {
  content: none;
}
/* 下拉宽度调整 */
.el-menu-item {
  width: 6rem;
}
</style>
<style>
.nav-container .el-menu--horizontal > .el-submenu {
  height: 50px;
  line-height: 50px;
}

/* 标题颜色改变 */
.nav-container .el-menu--horizontal > .el-submenu .el-submenu__title {
  height: 50px;
  line-height: 50px;
}
/* hover颜色改变 */
.nav-container .el-menu--horizontal > .el-submenu .el-submenu__title:hover {
  color: rgb(102, 97, 97);
}
</style>
