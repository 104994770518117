<template>
  <Router-Link to="/chat" target="_blank">
    <div
      class="chat-button fixed right-10 bottom-6 flex items-center justify-center rounded-full z-50 select-none cursor-pointer bg-[#1f93ff] p-3"
      :class="{ 'animated shake': shake }"
    >
      <IconMessage class="w-16 h-16 text-white"> </IconMessage>
      <span
        v-if="unReadCount"
        class="absolute -top-1 right-1 bg-red-600 text-white rounded-full px-2.5 font-semibold text-xl"
      >
        {{ badge }}</span
      >
    </div>
  </Router-Link>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { getUnReadMessageCount } from "@/api/game";
import { useIntervalFn, useLocalStorage, useEventBus } from "@vueuse/core";
import { MessageBox } from "element-ui";
import { useRouter } from "@/composables/router";
import IconMessage from "@/components/Icon/IconMessage.vue";
import { useStore } from "../store";

let timer;

const firstSetAutoReply = useLocalStorage("FITST_SET_AUTO_REPLY", false);

const bus = useEventBus("CHAT_MESSAGE_UNREAD_COUNT");

const router = useRouter();

const store = useStore();

const unReadCount = ref(0);

onMounted(() => {
  timer = useIntervalFn(
    async () => {
      const { data } = await getUnReadMessageCount();
      unReadCount.value = data.unReadCount;
    },
    1000 * 30,
    { immediateCallback: true }
  );
  if (!firstSetAutoReply.value) {
    MessageBox.confirm(
      "聊天功能已上线，如果不在线，可设置自动回复",
      "自动回复",
      {
        confirmButtonText: "前往设置",
        cancelButtonText: "取消",
      }
    )
      .then(() => {
        store.commit("setChatActiveMenu", "settings");
        const route = router.resolve({ path: "/chat" });
        window.open(route.href, "_blank");
      })
      .catch(() => {})
      .finally(() => {
        firstSetAutoReply.value = true;
      });
  }
});

onUnmounted(() => {
  timer.pause();
});

const badge = computed(() => {
  return unReadCount.value > 99 ? "99+" : unReadCount.value;
});

const shake = ref(false);

watch(
  unReadCount,
  (newValue, oldValue) => {
    bus.emit(newValue);
    if (newValue > oldValue) {
      shake.value = true;
      setTimeout(() => {
        shake.value = false;
      }, 1000);
    }
  },
  { immediate: true }
);
</script>
