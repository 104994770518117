import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: (resolve) => require(["../views/home/gameHome.vue"], resolve),
  },
  {
    path: "/game",
    component: (resolve) =>
      require(["../views/game/category/category.vue"], resolve),
  },
  {
    path: "/auctionGoods",
    component: (resolve) =>
      require(["../views/game/category/category.vue"], resolve),
  },
  {
    path: "/auctionHistory",
    component: (resolve) =>
      require(["../views/game/auction/aution_history.vue"], resolve),
  },
  {
    path: "/bussiness",
    component: (resolve) =>
      require(["../views/game/bussiness/buiness.vue"], resolve),
  },
  {
    path: "/chat",
    component: (resolve) => require(["../views/game/chat/chat.vue"], resolve),
  },
  {
    path: "/game/info/:goodsId",
    component: (resolve) => require(["../views/game/goods/info.vue"], resolve),
  },
  {
    path: "/game/user",
    component: (resolve) => require(["../views/game/user/User.vue"], resolve),
    meta: {
      title: "个人中心",
    },
  },
  {
    path: "/game/pubGoods",
    component: (resolve) =>
      require(["../views/game/user/goods/pubGoods.vue"], resolve),
    meta: {
      title: "发布商品",
    },
  },
  {
    path: "/game/myGoods",
    component: (resolve) =>
      require(["../views/game/user/goods/list.vue"], resolve),
    meta: {
      title: "商品管理",
    },
  },
  {
    path: "/articles/:articleId",
    component: (resolve) => require(["../views/article/Article.vue"], resolve),
  },
  {
    path: "/albums/:albumId",
    component: (resolve) => require(["../views/album/Photo.vue"], resolve),
  },
  {
    path: "/categories",
    component: (resolve) =>
      require(["../views/category/Category.vue"], resolve),
    meta: {
      title: "分类",
    },
  },
  {
    path: "/categories/:categoryId",
    component: (resolve) =>
      require(["../views/article/ArticleList.vue"], resolve),
  },
  {
    path: "/tags/:tagId",
    component: (resolve) =>
      require(["../views/article/ArticleList.vue"], resolve),
  },
  {
    path: "/links",
    component: (resolve) => require(["../views/link/Link.vue"], resolve),
    meta: {
      title: "友链列表",
    },
  },
  {
    path: "/about",
    component: (resolve) => require(["../views/about/About.vue"], resolve),
    meta: {
      title: "关于我",
    },
  },
  {
    path: "/test",
    component: (resolve) => require(["../views/test.vue"], resolve),
  },
  {
    path: "/privacy",
    component: (resolve) => require(["../views/privacy/Private.vue"], resolve),
  },
];

const router = new VueRouter({
  // mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
