import Vue from "vue";
import App from "./App.vue";
import router from "./router/gameIndex";
// import App from "./APPWeb.vue";
// import router from "./router/web_index";
import "animate.css";
import axios from "axios";
import dayjs from "dayjs";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "highlight.js/styles/atom-one-dark.css";
import NProgress from "nprogress";
import "nprogress/nprogress.css";
import VueAxios from "vue-axios";
import VueImageSwipe from "vue-image-swipe";
import "vue-image-swipe/dist/vue-image-swipe.css";
import InfiniteLoading from "vue-infinite-loading";
import Share from "vue-social-share";
import "./assets/css/common.less";
import "./assets/css/game_index.css";
import "./assets/css/iconfont.css";
import "./assets/css/markdown.css";
import "./assets/css/tailwind.css";
import "./assets/css/vue-social-share/client.css";
import config from "./assets/js/config";
import Toast from "./components/toast/index";
import i18n from './lang/index';
import vuetify from "./plugins/vuetify";
import store from "./store";

Vue.prototype.config = config;
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.use(Share);
Vue.use(InfiniteLoading);
Vue.use(VueAxios, axios);
Vue.use(VueImageSwipe);
Vue.use(Toast);


Vue.filter("date", function (value) {
  return dayjs(value).format("YYYY-MM-DD");
});

Vue.filter("year", function (value) {
  return dayjs(value).format("YYYY");
});

Vue.filter("hour", function (value) {
  return dayjs(value).format("HH:mm:ss");
});

Vue.filter("num", function (value) {
  if (value >= 1000) {
    return (value / 1000).toFixed(1) + "k";
  }
  return value;
});

router.beforeEach((to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", to.fullPath]);
    }
  }
  NProgress.start();
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

router.afterEach(() => {
  window.scrollTo({
    top: 0,
    behavior: "instant",
  });
  NProgress.done();
});

axios.interceptors.response.use(
  function (response) {
    switch (response.data.code) {
      case 50000:
        Vue.prototype.$toast({ type: "error", message: "系统异常" });
    }
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);

Vue.directive("drag", {
  bind(el) {
    let setPos = {
      x: 0,
      y: 0,
    };
    let lastPos = {
      x: 0,
      y: 0,
    };
    function move(curPos) {
      const d = {
        dX: curPos.x - lastPos.x,
        dY: curPos.y - lastPos.y,
      };
      setPos.x += d.dX;
      setPos.y += d.dY;
      el.style.transform = `translate(${setPos.x}px,${setPos.y}px)`;
      lastPos.x = curPos.x;
      lastPos.y = curPos.y;
    }
    el.addEventListener("mousedown", (e) => {
      e.stopPropagation();
      lastPos.x = e.clientX;
      lastPos.y = e.clientY;
      document.addEventListener("mousemove", mouseMove);
      document.addEventListener("mouseup", mouseUp);
    });
    function mouseMove(e) {
      const curPos = {
        x: e.clientX,
        y: e.clientY,
      };
      move(curPos);
    }
    function mouseUp(e) {
      e.stopPropagation();
      const curPos = {
        x: e.clientX,
        y: e.clientY,
      };
      move(curPos);
      document.removeEventListener("mousemove", mouseMove);
      document.removeEventListener("mouseup", mouseUp);
    }
  },
});
Vue.config.productionTip = false;

Vue.config.errorHandler = (err) => {
  console.error(err);
};

new Vue({
  router,
  store,
  i18n,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
