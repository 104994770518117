<template>
  <v-navigation-drawer
    app
    v-model="drawer"
    width="250"
    disable-resize-watcher
    right
    overlay-opacity="0.8"
  >
    <!-- 博主介绍 -->
    <div class="blogger-info">
      <v-avatar size="110" style="margin-bottom:0.5rem">
        <img
          :src="getFileUrl(this.$store.state.blogInfo.webSite.authorAvatar)"
        />
      </v-avatar>
    </div>

    <hr />
    <!-- 页面导航 -->
    <div class="menu-container">
      <div class="menus-item">
        <router-link to="/">
          <i class="iconfont iconzhuye" /> 首页
        </router-link>
      </div>
      <div class="menus-item">
        <router-link to="/game">
          <i class="iconfont iconguidang" /> 游戏
        </router-link>
      </div>

      <div class="menus-item" v-if="!this.$store.state.avatar">
        <a @click="openLogin"><i class="iconfont icondenglu" /> 登录 </a>
      </div>
      <div v-else>
        <div class="menus-item">
          <router-link to="/game/user">
            <i class="iconfont icongerenzhongxin" /> 个人中心
          </router-link>
        </div>
        <div class="menus-item">
          <router-link to="/game/pubGoods">
            <i class="iconfont icongerenzhongxin" /> 我要卖号
          </router-link>
        </div>
        <div class="menus-item">
          <router-link to="/game/myGoods">
            <i class="iconfont icongerenzhongxin" /> 商品管理
          </router-link>
        </div>
        <div class="menus-item">
          <a @click="logout"><i class="iconfont icontuichu" /> 退出</a>
        </div>
      </div>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { logout } from "@/api";
import { getFileUrl, compressFile } from "@/utils/imageUtil";

export default {
  data: function() {
    return {
      img: process.env.VUE_APP_IMG_API,
    };
  },
  computed: {
    drawer: {
      set(value) {
        this.$store.state.drawer = value;
      },
      get() {
        return this.$store.state.drawer;
      },
    },
    isLogin() {
      return this.$store.state.userId;
    },
  },
  methods: {
    getFileUrl(url) {
      return getFileUrl(url);
    },
    openLogin() {
      this.$store.state.loginFlag = true;
    },
    logout() {
      //如果在个人中心则跳回上一页
      if (this.$route.path === "/game/user") {
        this.$router.go(-1);
      }
      logout()
        .then((res) => {
          this.$store.commit("logout");
          this.$toast({ type: "success", message: "注销成功" });
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>

<style scoped>
.blogger-info {
  padding: 26px 30px 0;
  text-align: center;
}
.blog-info-wrapper {
  display: flex;
  align-items: center;
  padding: 12px 10px 0;
}
.blog-info-data {
  flex: 1;
  line-height: 2;
  text-align: center;
}
hr {
  border: 2px dashed #d2ebfd;
  margin: 20px 0;
}
.menu-container {
  padding: 0 10px 40px;
  animation: 0.8s ease 0s 1 normal none running sidebarItem;
}
.menus-item a {
  padding: 6px 30px;
  display: block;
  line-height: 2;
}
.menus-item i {
  margin-right: 2rem;
}
@keyframes sidebarItem {
  0% {
    transform: translateX(200px);
  }
  100% {
    transform: translateX(0);
  }
}
</style>
