<template>
  <Router-Link to="/chat" target="_blank">
    <div
      class="chat-button flex items-center justify-center rounded-full z-50 select-none cursor-pointer p-3 relative"
      :class="{ 'animated shake': shake }"
    >
      <IconMessage class="w-8 h-8 text-white"> </IconMessage>
      <span
        v-if="unReadCount"
        class="absolute top-1 right-1 bg-red-600 text-white rounded-full px-2.5 font-semibold text-sm"
      >
        {{ badge }}</span
      >
    </div>
  </Router-Link>
</template>

<script setup>
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useEventBus } from "@vueuse/core";
import IconMessage from "@/components/Icon/IconMessage.vue";

const unReadCount = ref(0);

const bus = useEventBus("CHAT_MESSAGE_UNREAD_COUNT");

const setCount = (count) => {
  unReadCount.value = count;
};

onMounted(() => {
  bus.on(setCount);
});

onUnmounted(() => {
  bus.off(setCount);
});

const badge = computed(() => {
  return unReadCount.value > 99 ? "99+" : unReadCount.value;
});

const shake = ref(false);

watch(
  unReadCount,
  (newValue, oldValue) => {
    if (newValue > oldValue) {
      shake.value = true;
      setTimeout(() => {
        shake.value = false;
      }, 1000);
    }
  },
  { immediate: true }
);
</script>
