<template>
  <v-dialog v-model="loginFlag" :fullscreen="isMobile" max-width="460">
    <v-card class="login-container" style="border-radius: 4px">
      <v-icon class="float-right" @click="loginFlag = false">
        mdi-close
      </v-icon>
      <div class="login-wrapper">
        <template v-if="useMobile">
          <!-- 用户名 -->
          <div style="font-size: 12px">手机号</div>
          <el-input placeholder="请输入您的手机号" v-model="mobile"></el-input>
          <!-- 密码 -->
          <div style="font-size: 12px; margin-top: 10px">密码</div>
          <el-input
            placeholder="请输入您的密码"
            v-model="password"
            show-password
            type="password"
          ></el-input>
        </template>
        <template v-else>
          <!-- 用户名 -->
          <div style="font-size: 12px">邮箱名</div>
          <el-input
            placeholder="请输入您的邮箱号"
            v-model="username"
            type="email"
          ></el-input>
          <!-- 密码 -->
          <div style="font-size: 12px; margin-top: 10px">密码</div>
          <el-input
            placeholder="请输入您的密码"
            v-model="password"
            show-password
            type="password"
          ></el-input>
        </template>
        <!-- 按钮 -->
        <v-btn
          class="mt-7"
          block
          color="blue"
          :disabled="false"
          style="color: #fff"
          @click="login"
        >
          登录
        </v-btn>

        <div
          class="mt-5 text-sm cursor-pointer"
          @click="useMobile = !useMobile"
        >
          {{ useMobile ? "邮箱登录" : "手机登录" }}
        </div>

        <!-- 注册和找回密码 -->
        <div class="mt-5 login-tip">
          <!--          <span  @click="openRegister">立即注册</span>
                    <span  @click="openForget" class="float-right">忘记密码?</span> -->
          <span @click="openRegister">立即注册</span>
          <span @click="openForget" class="float-right">忘记密码?</span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { emailLogin, mobileLogin } from "@/api";
export default {
  data: function () {
    return {
      username: "",
      password: "",
      show: false,
      useMobile: true,
      mobile: "",
    };
  },
  computed: {
    loginFlag: {
      set(value) {
        // console.log('222')
        this.$store.state.loginFlag = value;
      },
      get() {
        // console.log('111')
        return this.$store.state.loginFlag;
      },
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth > 960) {
        return false;
      }
      return true;
    },
    socialLoginList() {
      return this.$store.state.blogInfo.webSite.loginTypeList;
    },
    showLogin() {
      return function (type) {
        return this.socialLoginList.indexOf(type) != -1;
      };
    },
  },
  methods: {
    openRegister() {
      this.$store.state.loginFlag = false;
      this.$store.state.registerFlag = true;
    },
    openForget() {
      this.$store.state.loginFlag = false;
      this.$store.state.forgetFlag = true;
    },
    login() {
      if (this.useMobile) {
        if (!/^1[3-9]\d{9}$/.test(this.mobile)) {
          this.$toast({ type: "error", message: "手机格式不正确" });
          return;
        }
        mobileLogin({ tel: this.mobile, password: this.password })
          .then((res) => {
            this.$store.commit("login", res.data);
            this.$store.commit("closeModel");
            this.$toast({ type: "success", message: "登录成功" });
          })
          .catch((err) => {
            this.$toast({ type: "error", message: err.message });
          });
      } else {
        var reg =
          /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(this.username)) {
          // console.log(this.username)
          this.$toast({ type: "error", message: "邮箱格式不正确" });
          return false;
        }
        if (this.password.trim().length === 0) {
          this.$toast({ type: "error", message: "密码不能为空" });
          return false;
        }
        //发送登录请求
        emailLogin({ email: this.username, password: this.password })
          .then((res) => {
            this.$store.commit("login", res.data);
            this.$store.commit("closeModel");
            this.$toast({ type: "success", message: "登录成功" });
          })
          .catch((err) => {
            this.$toast({ type: "error", message: err.message });
          });
      }
    },
    qqLogin() {
      //保留当前路径
      this.$store.commit("saveLoginUrl", this.$route.path);
      if (
        navigator.userAgent.match(
          /(iPhone|iPod|Android|ios|iOS|iPad|Backerry|WebOS|Symbian|Windows Phone|Phone)/i
        )
      ) {
        // eslint-disable-next-line no-undef
        QC.Login.showPopup({
          appId: this.config.QQ_CLIENT_ID,
          redirectURI: this.config.QQ_REDIRECT_URL,
        });
      } else {
        window.open(
          "https://graph.qq.com/oauth2.0/show?which=Login&display=pc&client_id=" +
            +this.config.QQ_CLIENT_ID +
            "&response_type=token&scope=all&redirect_uri=" +
            this.config.QQ_REDIRECT_URL,
          "_self"
        );
      }
    },
    giteeLogin() {
      //保留当前路径
      this.$store.commit("saveLoginUrl", this.$route.path);
      window.open(
        "https://gitee.com/oauth/authorize?client_id=" +
          this.config.GITEE_CLIENT_ID +
          "&response_type=code&redirect_uri=" +
          this.config.GITEE_REDIRECT_URL,
        "_self"
      );
    },
    weiboLogin() {
      //保留当前路径
      this.$store.commit("saveLoginUrl", this.$route.path);
      window.open(
        "https://api.weibo.com/oauth2/authorize?client_id=" +
          this.config.WEIBO_CLIENT_ID +
          "&response_type=code&redirect_uri=" +
          this.config.WEIBO_REDIRECT_URL,
        "_self"
      );
    },
  },
};
</script>

<style scoped>
.social-login-title {
  margin-top: 1.5rem;
  color: #b5b5b5;
  font-size: 0.75rem;
  text-align: center;
}

.social-login-title::before {
  content: "";
  display: inline-block;
  background-color: #d8d8d8;
  width: 60px;
  height: 1px;
  margin: 0 12px;
  vertical-align: middle;
}

.social-login-title::after {
  content: "";
  display: inline-block;
  background-color: #d8d8d8;
  width: 60px;
  height: 1px;
  margin: 0 12px;
  vertical-align: middle;
}

.social-login-wrapper {
  margin-top: 1rem;
  font-size: 2rem;
  text-align: center;
}

.social-login-wrapper a {
  text-decoration: none;
}
</style>
