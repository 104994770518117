import { useLocalStorage } from "@vueuse/core";
import Vue from "vue";
import Vuex from "vuex";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const token = useLocalStorage("token", "");

const store = new Vuex.Store({
  state: {
    socket: null,
    isMobile: false,
    searchFlag: false,
    loginFlag: false,
    registerFlag: false,
    forgetFlag: false,
    emailFlag: false,
    drawer: false,
    loginUrl: "",
    userId: null,
    isAuction: 0, //拍卖权限
    avatar: null,
    nickname: null,
    intro: null,
    webSite: null,
    loginType: null,
    email: null,
    qq: null,
    wx: null,
    tel: null,
    token: null,
    lang:"",
    dialogFormVisible: false,
    articleLikeSet: [],
    commentLikeSet: [],
    isCustomerService: false,
    blogInfo: {
      webSite: {
        authorAvatar: null,
        authorInfo: null,
        touristAvatar: null,
        loginTypeList: "",
        bulletin: "11",
        aboutMe: null,
        logo: null,
        github: null,
        gitee: null,
        qqNumber: null,
        recordNum: null,
        author: null,
        aliPay: null,
        weixinPay: null,
        keyword: null,
        name: null,
        summary: null,
        webUrl: null,
        showList: "",
      },
      count: {
        articleCount: null,
        categoryCount: null,
        tagCount: null,
        viewsCount: null,
      },
      pageList: [
        {
          pageCover: null,
          pageLabel: null,
        },
      ],
    },
    //游戏账号信息
    game: {
      gameCategoryList: null,
      queryGoods: {
        //当前显示的标签
        showTags: [],
        //已选择的分类
        selCategory: [],
        params: {
          currentIndex: null,
          tagIds: [],
          middleCategoryId: null,
          minPrice: null,
          maxPrice: null,
          name: null,
        },
      },
    },
    chatGoods: undefined,
    chatActiveMenu: undefined,
  },
  mutations: {
    login(state, user) {
      // console.log(user);
      state.userId = user.id;
      state.isAuction = user.isAuction;
      state.avatar = user.avatar;
      state.nickname = user.nickname;
      state.intro = user.intro;
      state.webSite = user.webSite;
      state.articleLikeSet = user.articleLikeSet ? user.articleLikeSet : [];
      state.commentLikeSet = user.commentLikeSet ? user.commentLikeSet : [];
      state.email = user.email;
      state.token = user.token;
      state.loginType = user.loginType;
      state.qq = user.qq;
      state.wx = user.wx;
      state.tel = user.tel;
      state.isCustomerService = user.isCustomerService;
      token.value = user.token;
    },
    logout(state) {
      state.userId = null;
      state.isAuction = null;
      state.avatar = null;
      state.nickname = null;
      state.intro = null;
      state.token = null;
      state.webSite = null;
      state.articleLikeSet = [];
      state.commentLikeSet = [];
      state.email = null;
      state.loginType = null;
      state.qq = null;
      state.wx = null;
      state.tel = null;
      state.isCustomerService = false;
      token.value = "";
    },
    saveLoginUrl(state, url) {
      state.loginUrl = url;
    },
    saveEmail(state, email) {
      state.email = email;
    },
    updateUserInfo(state, user) {
      state.nickname = user.nickname;
      state.intro = user.intro;
      state.webSite = user.webSite;
      state.qq = user.qq;
      state.wx = user.wx;
      state.tel = user.tel;
    },
    savePageInfo(state, pageList) {
      state.pageList = pageList;
    },
    updateAvatar(state, avatar) {
      state.avatar = avatar;
    },
    checkBlogInfo(state, blogInfo) {
      state.blogInfo = blogInfo;
    },
    closeModel(state) {
      state.registerFlag = false;
      state.loginFlag = false;
      state.searchFlag = false;
      state.emailFlag = false;
    },
    articleLike(state, articleId) {
      var articleLikeSet = state.articleLikeSet;
      if (articleLikeSet.indexOf(articleId) != -1) {
        articleLikeSet.splice(articleLikeSet.indexOf(articleId), 1);
      } else {
        articleLikeSet.push(articleId);
      }
    },
    commentLike(state, commentId) {
      var commentLikeSet = state.commentLikeSet;
      if (commentLikeSet.indexOf(commentId) != -1) {
        commentLikeSet.splice(commentLikeSet.indexOf(commentId), 1);
      } else {
        commentLikeSet.push(commentId);
      }
    },
    setDialogFormVisible(state) {
      state.dialogFormVisible = !state.dialogFormVisible;
    },
    //
    gameCategoryList(state, list) {
      state.game.gameCategoryList = list;
    },
    setChatSendGoodsInfo(state, goods) {
      state.chatGoods = goods;
    },
    setChatActiveMenu(state, menu) {
      state.chatActiveMenu = menu;
    },
    
    
  },
  actions: {},
  modules: {},
  plugins: [
    createPersistedState({
      storage: window.localStorage,
      reducer: (state, paths) => {
        const reducer = Object.assign({}, state, {
          game: Object.assign({}, state.game),
        });
        delete reducer.game.gameCategoryList;
        return reducer;
      },
    }),
  ],
});

export default store;

export const useStore = () => store;
