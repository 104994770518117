<template>
  <v-dialog v-model="forgetFlag" :fullscreen="isMobile" max-width="460">
    <v-card class="login-container" style="border-radius: 4px">
      <v-icon class="float-right" @click="forgetFlag = false">
        mdi-close
      </v-icon>
      <div class="login-wrapper">
        <template v-if="useMobile">
          <!-- 用户名 -->
          <div style="font-size: 12px">手机号</div>
          <el-input placeholder="请输入您的手机号" v-model="mobile"></el-input>

          <!-- 密码 -->
          <div style="font-size: 12px; margin-top: 10px">新密码</div>
          <el-input
            placeholder="请输入您的新密码"
            v-model="password"
            show-password
            type="password"
          ></el-input>

          <!-- 验证码 -->
          <div style="font-size: 12px; margin-top: 10px">验证码</div>
          <div class="send-wrapper">
            <ElInput
              maxlength="6"
              v-model="smsCode"
              placeholder="请输入6位验证码"
              @keyup.enter="register"
            />
            <v-btn
              text
              small
              :disabled="!enabledSendSMSCode || time < 60"
              @click="sendSMSCode"
            >
              {{ codeMsg }}
            </v-btn>
          </div>
        </template>
        <template v-else>
          <!-- 用户名 -->
          <div style="font-size: 12px">邮箱名</div>
          <el-input
            placeholder="请输入您的邮箱号"
            v-model="username"
            type="email"
          ></el-input>

          <!-- 密码 -->
          <div style="font-size: 12px; margin-top: 10px">新密码</div>
          <el-input
            placeholder="请输入您的新密码"
            v-model="password"
            show-password
            type="password"
          ></el-input>

          <!-- 验证码 -->
          <div style="font-size: 12px; margin-top: 10px">验证码</div>
          <div class="send-wrapper">
            <ElInput
              maxlength="6"
              v-model="code"
              placeholder="请输入6位验证码"
              @keyup.enter="forget"
            />
            <v-btn :disabled="flag" text small @click="sendCode">
              {{ codeMsg }}
            </v-btn>
          </div>
        </template>

        <!-- 按钮 -->
        <v-btn
          class="mt-7"
          block
          color="green"
          style="color: #fff"
          @click="forget"
        >
          确定
        </v-btn>

        <div
          class="mt-5 text-sm cursor-pointer"
          @click="useMobile = !useMobile"
        >
          {{ useMobile ? "使用邮箱重置" : "使用手机重置" }}
        </div>

        <!-- 登录 -->
        <div class="mt-5 login-tip">
          已有账号？<span @click="openLogin">登录</span>
        </div>
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  sendEmailCode,
  updatePassword,
  sendSMSCode,
  mobileUpdatePassword,
} from "@/api";
export default {
  data: function () {
    return {
      username: "",
      code: "",
      password: "",
      flag: true,
      codeMsg: "发送",
      time: 60,
      show: false,
      useMobile: true,
      mobile: "",
      smsCode: "",
    };
  },
  methods: {
    openLogin() {
      this.$store.state.forgetFlag = false;
      this.$store.state.loginFlag = true;
    },
    sendCode() {
      //发送邮件
      this.countDown();
      sendEmailCode(this.username)
        .then((res) => {
          this.$toast({ type: "success", message: res.message });
        })
        .catch((err) => {
          this.$toast({ type: "error", message: err.message });
        });
    },
    countDown() {
      this.flag = true;
      this.timer = setInterval(() => {
        this.time--;
        this.codeMsg = this.time + "s";
        if (this.time <= 0) {
          clearInterval(this.timer);
          this.codeMsg = "发送";
          this.time = 60;
          this.flag = false;
        }
      }, 1000);
    },
    sendSMSCode() {
      if (!this.isMobileNumber(this.mobile)) {
        this.$toast({ type: "error", message: "手机格式不正确" });
        return;
      }
      this.countDown();
      sendSMSCode(this.mobile, 1)
        .then((res) => {
          this.$toast({ type: "success", message: res.message });
        })
        .catch((err) => {
          this.$toast({ type: "error", message: err.message });
        });
    },
    forget() {
      if (this.useMobile) {
        if (!this.isMobileNumber(this.mobile)) {
          this.$toast({ type: "error", message: "手机格式不正确" });
          return;
        }
        if (this.smsCode.trim().length !== 6) {
          this.$toast({ type: "error", message: "请输入6位验证码" });
          return;
        }
        if (this.password.trim().length < 6) {
          this.$toast({ type: "error", message: "密码不能少于6位" });
          return;
        }
        const user = {
          tel: this.mobile,
          password: this.password,
          code: this.smsCode,
        };
        mobileUpdatePassword(user)
          .then((res) => {
            this.$toast({ type: "success", message: res.message });
            this.openLogin();
          })
          .catch((err) => {
            this.$toast({ type: "error", message: err.message });
          });
      } else {
        var reg =
          /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
        if (!reg.test(this.username)) {
          this.$toast({ type: "error", message: "邮箱格式不正确" });
          return false;
        }
        if (this.code.trim().length !== 6) {
          this.$toast({ type: "error", message: "请输入6位验证码" });
          return false;
        }
        if (this.password.trim().length < 6) {
          this.$toast({ type: "error", message: "密码不能少于6位" });
          return false;
        }
        const user = {
          email: this.username,
          password: this.password,
          code: this.code,
        };
        updatePassword(user)
          .then((res) => {
            this.$toast({ type: "success", message: res.message });
            this.openLogin();
          })
          .catch((err) => {
            this.$toast({ type: "error", message: err.message });
          });
      }
    },
    isMobileNumber(mobile) {
      return /^1[3-9]\d{9}$/.test(mobile);
    },
  },
  computed: {
    forgetFlag: {
      set(value) {
        this.$store.state.forgetFlag = value;
      },
      get() {
        return this.$store.state.forgetFlag;
      },
    },
    isMobile() {
      const clientWidth = document.documentElement.clientWidth;
      if (clientWidth > 960) {
        return false;
      }
      return true;
    },
    enabledSendSMSCode() {
      return this.isMobileNumber(this.mobile);
    },
  },
  watch: {
    username(value) {
      var reg = /^[A-Za-z0-9\u4e00-\u9fa5]+@[a-zA-Z0-9_-]+(\.[a-zA-Z0-9_-]+)+$/;
      if (reg.test(value)) {
        this.flag = false;
      } else {
        this.flag = true;
      }
    },
  },
};
</script>
