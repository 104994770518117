import ImageCompressor from "compressorjs";

/**
 * @param {String} path
 */
export function getFileUrl(path) {
  if (path) {
    if (path.indexOf("http") == 0 || path.indexOf("https") == 0) {
      return path;
    } else if (path.indexOf("/") == 0) {
      return process.env.VUE_APP_IMG_API + path.substring(1, path.length);
    } 
  }
  return path;
}

//压缩文件
export function compressFile(file, options = {}) {
  return new Promise(async (resolve, reject) => {
    const { maxWidth = 1024, quality = 0.6 } = options;
    new ImageCompressor(file, {
      maxWidth,
      quality, //压缩质量
      mimeType: "image/jpeg",
      checkOrientation: false, //图片翻转，默认为false
      success(result) {
        // console.log(result);
        resolve(result);
      },
      error(e) {
        console.log(e);
        reject(e);
      },
    });
  });
}
