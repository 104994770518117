import request from "@/utils/request";

export function getCategoryList(params) {
  return request({
    url: "/web/game/category/list",
    method: "get",
    params: params,
  });
}

export function getGoodsList(data) {
  return request({
    url: "/web/game/goods/list",
    method: "post",
    data,
  });
}

export function getGoodsAuctionHistoryList(data) {
  return request({
    url: "/web/game/goods/auctionList",
    method: "post",
    data,
  });
}

export function getGoodsInfo(params) {
  return request({
    url: "/web/game/goods/info",
    method: "get",
    params: params,
  });
}

export function getGoodsPriceInfo(params) {
  return request({
    url: "/web/game/goods/price",
    method: "get",
    params: params,
  });
}

export function getInsureList(goodsId) {
  return request({
    url: "/web/game/insure/list",
    method: "get",
    params: {
      goodsId: goodsId,
    },
  });
}

export function add(formData, onUploadProgress) {
  return request({
    url: "/web/game/goods/create",
    method: "POST",
    onUploadProgress,
    transformrequest: [
      function (data, headers) {
        // 去除post请求默认的content-type
        delete headers["content-type"];
        delete headers.post["Content-Type"];
        return data;
      },
    ],
    data: formData,
  });
}

export function update(formData, onUploadProgress) {
  return request({
    url: "/web/game/goods/update",
    method: "post",
    onUploadProgress,
    transformrequest: [
      function (data, headers) {
        // 去除post请求默认的content-type
        delete headers["content-type"];
        delete headers.post["Content-Type"];
        return data;
      },
    ],
    data: formData,
  });
}

export function getMyGoodsList(data) {
  return request({
    url: "/web/game/goods/myList",
    method: "post",
    data,
  });
}

export function delMyGoods(goodsId) {
  return request({
    url: "/web/game/goods/remove",
    method: "delete",
    params: {
      id: goodsId,
    },
  });
}

export function saleGoods(data) {
  return request({
    url: "/web/game/goods/update",
    method: "POST",
    params: data,
  });
}

export function getTags(gameCategoryId) {
  return request({
    url: "/web/game/goods/tag/list",
    method: "get",
    params: {
      gameCategoryId: gameCategoryId,
    },
  });
}

export function getAuctionList(gameGoodsId) {
  return request({
    url: "/web/game/auction/list",
    method: "get",
    params: {
      gameGoodsId: gameGoodsId,
    },
  });
}

export function addAuction(data) {
  return request({
    url: "/web/game/auction/create",
    method: "POST",
    params: data,
  });
}

export function clickBuy() {
  return request({
    url: "/web/game/goods/clickBuy",
    method: "get",
  });
}

//获取商人列表
export function getBussinessers(data) {
  return request({
    url: "/user/getBussinessers",
    method: "post",
    params: data,
  });
}

export function searchUser(params) {
  return request({
    url: "/user/webSearchUser",
    method: "post",
    params,
  });
}

export function getUnReadMessageCount() {
  return request({
    url: "/web/home/getUnReadMessageCount",
    method: "get",
  });
}

export function queryAutoReplySetting() {
  return request({
    url: "/user/queryAutoReplySetting",
    method: "post",
  });
}

export function updateAutoReplySetting(params) {
  return request({
    url: "/user/updateAutoReply",
    method: "post",
    params,
  });
}

/**
 * @param {object} data
 * @param {string} data.file
 */
export function recognize(data) {
  return request({
    url: "/web/game/goods/recognize",
    method: "post",
    params: data,
  });
}

/**
 * @param {object} data
 * @param {number} data.itemId
 */
export function getEquipAttribute(data) {
  return request({
    url: "/web/game/goods/queryEditEquipAttribute",
    method: "post",
    params: data,
  });
}
